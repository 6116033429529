import React from "react";
import Image from "../../elements/Image";
import { CardLine } from "../models.styles";
import { ColorsRow, ColorsRowImage, ColorsRowContent, CardTitle, CardText, ColorsGrid, ColorsItem } from "./ram.styles";

const BlockColors = ({title, data, activeColor, setActive}) => {
  return (
    <ColorsRow>
      <ColorsRowImage>
        {data.map((item, i) => (
          <div key={i} className={activeColor === item.id ? `wrapper active` : `wrapper`}>
            <Image fileName={item.image} alt={item.title} />
          </div>
        ))}
      </ColorsRowImage>
      <ColorsRowContent>
        <CardTitle>{title}</CardTitle>
        <CardLine/>
        <CardText className={`subtitle`}>{data[activeColor-1].title}</CardText>
        <ColorsGrid>
          {data.map((item, i) => (
            <ColorsItem key={i} className={activeColor === item.id ? `active` : ``} onClick={() => setActive(item.id)}>
              <Image fileName={item.thumb} alt={''} />
            </ColorsItem>
          ))}
        </ColorsGrid>
      </ColorsRowContent>
    </ColorsRow>
  )
}

export default BlockColors