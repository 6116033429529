import styled from 'styled-components'
import { breakpoints } from "../../../styles/breakpoints.styles";

export const Design = styled.div`
   width: 100%;
   background-color: #131415;
   padding: 2px 0 4rem;
   margin-top: 16rem;
`;

export const DesignRow = styled.div`
   display: flex;
   justify-content: space-between;
   
   ${breakpoints('tabPort') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const DesignCard = styled.div`
   display: flex;
   flex-direction: column;
   width: ${props => props.width ? props.width : "32%"};
   
   ${breakpoints('tabPort') `
    width: 100%;
    max-width: 40rem;
  `} 
   
   .gatsby-image-wrapper {
    margin-bottom: 2rem;
   }
`;

export const CardTitle = styled.div`
  color: ${props => props.color ? props.color : 'black'};
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: ${props => props.align ? props.align : 'left'};
  
  ${breakpoints('tabPort') `
    font-size: 2.2rem;
    text-align: center;
  `} 
`;

export const CardText = styled.div`
  color: ${props => props.color ? props.color : props.theme.colors.text};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: ${props => props.align ? props.align : 'left'};
  
  ul {
    font-size: 1.4rem;
    line-height: 2.4rem;
    list-style: none;
    margin-bottom: 6rem;
    
    li {
      position: relative;
      padding-left: 1.6rem;
      margin-bottom: 5px;
      
      ::before {
        content: "•"; 
        color: ${props => props.theme.colors.primary};
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const ColorsRow = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   
   ${breakpoints('tabPort') `
      flex-direction: column; 
      margin-bottom: 4rem
   `} 
`;

export const ColorsRowImage = styled.div`
   width: 70%;
   height: 30vw;
   position: relative;
   
   ${breakpoints('tabPort') `
      width: 100%;
      height: 48vw;
   `}
   
   .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s;
    
    &.active {
      opacity: 1;
    }
   }
`;

export const ColorsRowContent = styled.div`
   width: 30%;
   
   ${breakpoints('tabPort') `
      width: 100%;
   `}
   
   .subtitle {
     ${breakpoints('tabPort') `
        text-align: center;
     `}
   }
`;

export const ColorsItem = styled.div`
   width: 4rem;
   height: 4rem;
   border-radius: 50%;
   border: 5px solid #F1F1F1;
   margin-right: 1.4rem;
   margin-bottom: 1.4rem;
   
   &:hover { cursor: pointer;}
   
   &.active {
      box-shadow: 0 0 12px 0 rgba(81,82,128,0.5); 
   }
`;

export const ColorsGrid = styled.div`
   display: flex;
   flex-wrap: wrap;
   width: 24rem;
   margin-top: 2rem;
   
   ${breakpoints('tabPort') `
      margin: 2rem auto;
      justify-content: center;
   `}
`;

export const Engine = styled.div`
   width: 100%;
   background-color: #131415;
   padding: 2px 0 4rem;
   margin-top: 10rem;
`;

export const EngineRow = styled.div`
   display: flex;
   justify-content: space-evenly;
   
   ${breakpoints('tabPort') `
      flex-direction: column; 
      align-items: center;
   `} 
`;

export const EngineCard = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   text-align: left;
   width: 40rem;
   
   &:first-child {
    align-items: flex-end;
    text-align: right;
    
    ${breakpoints('tabPort') `
      align-items: center;
      text-align: center;  
   `}
   }
   
   ${breakpoints('tabPort') `
      width: 100%;
      max-width: 40rem;
      margin-bottom: 4rem;
      align-items: center;
      text-align: center;  
   `}
   
   .gatsby-image-wrapper {
     width: 100%;
     margin-bottom: 2rem;
   }
`;

export const EngineInfo = styled.div`
   display: flex;
   font-size: 1.4rem;
   width: 100%;
   margin-top: 2rem;
   
   .title {
    color: white;
    width: 50%;
   }
   
   span {
    color: #a7a7a7;
    width: 50%;
   }
`;

export const InteriorRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-top: 4rem;
`;

export const InteriorCard = styled.div`
   display: flex;
   flex-direction: column;
   width: 32%;
   
   ${breakpoints('tabPort') `
      width: 100%;
      max-width: 40rem;
      margin: 0 auto 4rem;
   `}
   
   .title {
    color: ${props => props.theme.colors.text};
    font-size: 1.4rem;
    margin-top: 2rem;
    text-align: center;
   } 
`;

export const InteriorCard2 = styled.div`
   display: flex;
   flex-direction: column;
   width: 48%;
   
   ${breakpoints('phone') `
      width: 100%;
      max-width: 40rem;
      margin: 0 auto 4rem;
   `}
   
   .title {
    color: ${props => props.theme.colors.text};
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 2rem;
    text-align: center;
   } 
`;

export const OptionsRow = styled.div`
   display: flex;
   flex-wrap: wrap;
`;

export const OptionsCard = styled.div`
   display: flex;
   flex-direction: column;
   width: 24%;
   margin-right: 1%;
   margin-bottom: 6rem;
   
   &:nth-of-type(4n) {
      ${breakpoints('tabPort') `
        margin: 0 auto 4rem;
     `}
   }
   
   ${breakpoints('tabPort') `
      width: 100%;
      max-width: 40rem;
      margin: 0 auto 4rem;
   `}
   
   .text {
    padding: 2rem;
   }
`;

export const Standard = styled.div`
   width: 100%;
   background-color: #131415;
   padding: 2px 0 4rem;
`;

export const Video = styled.div`
   width: 100%;
   margin: 4rem 0;
   
   iframe {
    width: 100%;
    height: 47vw;
    max-height: 80rem;
   }
`;